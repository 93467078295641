import React from 'react'

export default function UserCircleError({ width = 31, height = 30, color = 'currentColor' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='UserCircleError'>
        <path
          id='Vector'
          d='M15.5 18.75C18.0888 18.75 20.1875 16.6513 20.1875 14.0625C20.1875 11.4737 18.0888 9.375 15.5 9.375C12.9112 9.375 10.8125 11.4737 10.8125 14.0625C10.8125 16.6513 12.9112 18.75 15.5 18.75Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M7.97656 23.3672C8.68193 21.9778 9.75823 20.8109 11.0862 19.9958C12.4141 19.1807 13.9418 18.7493 15.5 18.7493C17.0582 18.7493 18.5859 19.1807 19.9138 19.9958C21.2418 20.8109 22.3181 21.9778 23.0234 23.3672'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M21.9487 4.57373L25.9262 8.55121'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M25.9263 4.57373L21.9488 8.55121'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_5'
          d='M26.6094 13.2305C26.7041 13.8155 26.7512 14.4073 26.75 15C26.75 17.2251 26.0902 19.4001 24.854 21.2502C23.6179 23.1002 21.8609 24.5422 19.8052 25.3937C17.7495 26.2452 15.4875 26.4679 13.3052 26.0339C11.123 25.5998 9.11839 24.5283 7.54505 22.955C5.97171 21.3816 4.90025 19.3771 4.46617 17.1948C4.03209 15.0125 4.25487 12.7505 5.10636 10.6948C5.95785 8.63916 7.39979 6.88215 9.24984 5.64599C11.0999 4.40982 13.275 3.75002 15.5 3.75002C16.0927 3.74886 16.6845 3.79589 17.2695 3.89065'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
