import axios from 'axios'

import { useState, useEffect } from 'react'
import { useSearchParams } from 'next/navigation'

import LinkButton from '../../components/redesign/Link'
import { useRouter } from 'next/router'

import { useForm } from 'react-hook-form'
import { useGoogleLogin } from '@react-oauth/google'
import * as Sentry from '@sentry/nextjs'

import Loader from '../../components/loader'
import WelcomeLayout from '../../components/redesign/WelcomeLayout'
import Input from '@howdy/core/elements/Input'

import { useUser } from '../../contexts/userContext'
import { useTracking } from '../../contexts/gaTrackerContext'
import setUser from '../../actions/setUser'
import NoUserModal from '../../modules/login/noUserModal'
import useTCs from '../../hooks/useTCs'
import TCModal from '../../modules/sign-up/TCModal'
import ProviderTCModal from '../../modules/sign-up/ProviderTCModal'
import PasswordInput from '../../components/redesign/PasswordInput'
import Button from '@howdy/core/elements/Button'
import EnvelopeSimple from '@howdy/core/elements/Icons/EnvelopeSimple'
import Image from 'next/image'
import useMobile from '../../hooks/useMobile'
import { errorToast, warningToast } from '@howdy/core/lib/toast'
import { useApple } from '../../packages/ui/src/lib/apple'

export default function Login() {
  const {
    userState: { data },
  } = useUser()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showNoUserModal, setShowNoUserModal] = useState(false)
  const [showTCModal, setShowTCModal] = useState(false)
  const [showTCProvider, setShowTCProvider] = useState(false)
  const [googleAccessToken, setGoogleAccesToken] = useState(null)
  const [shouldUpdateTcs, setShouldUpdateTcs] = useState(false)
  const [showEmailLogin, setShowEmailLogin] = useState(false)
  const { tcs } = useTCs()
  const { isMobile } = useMobile()

  const router = useRouter()
  const { signupMethod } = router.query
  const { email: queryEmail } = router.query

  const searchParams = useSearchParams()

  useEffect(() => {
    if (signupMethod == 'email') {
      setShowEmailLogin(true)
    }
  }, [signupMethod])

  useEffect(() => {
    if (data?.id) {
      router.replace('/dashboard')
    }
  }, [])

  useEffect(() => {
    if (queryEmail) {
      loginForm.setValue('email', queryEmail)
    }
  }, [queryEmail])

  const fieldsAreValid = () => {
    if (email.trim() === '') {
      warningToast({
        title: 'Invalid email',
        message: 'Email cannot be empty',
      })
      return false
    }
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!re.test(email)) {
      warningToast({
        title: 'Invalid email',
        message: 'Email is not valid',
      })
      return false
    }
    if (firstName.trim() === '') {
      warningToast({
        title: 'Invalid First Name',
        message: 'First Name cannot be empty',
      })
      return false
    }
    if (lastName.trim() === '') {
      warningToast({
        title: 'Invalid Last Name',
        message: 'Last Name cannot be empty',
      })
      return false
    }
    if (companyName.trim() === '') {
      warningToast({
        title: 'Invalid Company Name',
        message: 'Company Name cannot be empty',
      })
      return false
    }
    return true
  }

  const updateTCs = () => {
    axios
      .post('/terms', {
        version: tcs.activeSince,
      })
      .then(() => {
        router.query.redirect ? router.replace(router.query.redirect) : router.replace(`/dashboard`)
      })
  }

  const cancelUpdateTCs = () => {
    setShowTCModal(false)
    router.replace('/logout')
  }

  const onSendClick = () => {
    if (fieldsAreValid()) {
      setIsLoading(true)
      axios
        .post('/collaborator', {
          email,
          firstName,
          lastName,
          companyName,
          invitationSender: '',
        })
        .then(() => {
          getInvitations()
          // setStatus(0, successResponse.data.user.urlHash).then(()=>{})
          setEmail('')
          setFirstName('')
          setLastName('')
          setCompanyName('')
        })
        .catch((err) => {
          console.error(err)
          Sentry.captureException(err)
          setIsLoading(false)
        })
    }
  }

  const onAcceptTCProvider = () => {
    setShowTCProvider(false)
    setShowTCModal(false)
    axios
      .post('/partners/register', {
        googleId: googleAccessToken,
        url: window.location.href,
      })
      .then((registerResponse) => {
        dispatch(setUser({ email: registerResponse?.data?.email }))
        router.query.redirect ? router.replace(router.query.redirect) : router.replace(`/dashboard`)

        setIsLoading(false)
      })
      .catch((err) => {
        Sentry.captureException(err)
        errorToast({
          title: 'Login with google failed',
          message: err?.response?.data?.message || 'There was an error with google login',
        })
        setIsLoading(false)
      })
  }

  const { logEvent, setPageTitle } = useTracking()

  const { userState: user, dispatch } = useUser()
  const loginForm = useForm({})

  useEffect(() => {
    setPageTitle('Login')
  }, [])

  const modifyUser = async () => {
    const response = await axios.get('/users/getUser')
    if (response?.data?.user) {
      dispatch(setUser(response.data.user))
    }
    return response.data.user.acceptedTerms
  }

  const checkTCsVersion = (version, user) => {
    if (version != tcs.activeSince) {
      setIsLoading(false)
      setShouldUpdateTcs(true)
      setShowTCModal(true)
    } else if (user.role === 'LimitedAccess') {
      router.replace('my-teams/my-candidates')
    } else {
      router.query.redirect ? router.replace(router.query.redirect) : router.replace('/dashboard')
    }
  }

  const onSubmitLogin = ({ email, password }) => {
    Sentry.addBreadcrumb({
      type: 'info',
      category: 'started',
      message: `entered email:  ${email}`,
      level: 'info',
    })
    setIsLoading(true)
    axios
      .post('/login', {
        email: email,
        password: password,
      })
      .then(async (response) => {
        const acceptedTerms = await modifyUser()
        logEvent({
          category: 'Login',
          action: 'Loged in succesfully (email  and password)',
          label: 'user_loged_in_email_pass',
        })
        checkTCsVersion(acceptedTerms[acceptedTerms.length - 1].version, response.data.user)
      })
      .catch(function (err) {
        console.error(err)
        Sentry.captureException(err)
        logEvent({
          category: 'Login',
          action: 'Login failed (email  and password)',
          label: 'user_loging_failed_email_pass',
        })
        setIsLoading(false)
        if (err?.response?.status == 403) {
          setShowNoUserModal(true)
        } else {
          errorToast({
            title: 'Login failed',
            message: 'Invalid email or password',
          })
        }
      })
  }

  const onGoogleSuccess = useGoogleLogin({
    onSuccess: (response) => {
      const { access_token } = response
      axios
        .post('/login', {
          googleId: access_token,
        })
        .then(function (response) {
          logEvent({
            category: 'Login',
            action: 'Loged in succesfully (google auth)',
            label: 'user_loged_in_google_auth',
          })
          dispatch(setUser(response.data.user))
          const acceptedTerms = response.data.user.acceptedTerms

          checkTCsVersion(acceptedTerms[acceptedTerms.length - 1].version, response.data.user)
        })
        .catch(function (err) {
          Sentry.captureException(err)
          setGoogleAccesToken(access_token)
          logEvent({
            category: 'Login',
            action: 'Login failed (google auth)',
            label: 'user_loging_failed_google_auth',
          })
          if (err?.response?.status == 403) {
            setShowTCProvider(true)
          } else {
            errorToast({
              title: 'Login with google failed',
              message: err?.response?.data?.message || 'There was an error with google login',
            })
          }
        })
    },
  })

  const appleLogin = useApple({
    onSuccess: () => {
      window.top.location.href = `${searchParams.get('redirect') || '/dashboard'}`
    },
    onError: () => {
      console.log(`${searchParams.get('redirect') || '/dashboard'}`)
    },
  })

  const closeTCModal = () => {
    if (shouldUpdateTcs) {
      cancelUpdateTCs()
    } else {
      setShowTCModal(false)
    }
  }

  const onGoogleFailure = (response) => {
    const errorMessage =
      response?.error === 'idpiframe_initialization_failed'
        ? `Google login doesn't work in incognito mode`
        : 'There was an error with google login'

    Sentry.captureException(new Error(`Error login with google : ${response?.details}`))
    errorToast({
      title: 'Login with google failed',
      message: errorMessage,
    })
  }

  if (isLoading) {
    return <Loader loading={isLoading} />
  }

  const getQuery = () => {
    const query = router.query
    const queryMap = Object.keys(query).map((key) => `${key}=${encodeURIComponent(query[key])}`)
    return queryMap.length > 0 ? `?${queryMap.join('&')}` : ''
  }

  return (
    <WelcomeLayout pageTitle='Howdy | Login' title='Howdy!'>
      <div className='mt-9 flex w-full flex-col items-center pb-4 font-worksans lg:items-start'>
        <div className='mb-4 flex w-full flex-col gap-5 md:w-auto lg:max-w-[320px] lg:items-start'>
          <Button
            fullWidth={true}
            childType={!isMobile ? 'leftAligned' : 'center'}
            onClick={() => onGoogleSuccess()}
          >
            <div className='flex items-center gap-3'>
              <Image height={20} width={20} src='/google-icon.svg' />
              Login with Google
            </div>
          </Button>
          <Button
            fullWidth={true}
            childType={!isMobile ? 'leftAligned' : 'center'}
            onClick={appleLogin}
          >
            <span className='-mt-1 flex items-center gap-3'>
              <svg
                width='21'
                height='20'
                viewBox='0 0 21 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M17.989 6.81868C17.875 6.90869 15.8625 8.06281 15.8625 10.6291C15.8625 13.5974 18.4233 14.6475 18.5 14.6735C18.4882 14.7375 18.0932 16.1116 17.1498 17.5118C16.3086 18.7439 15.4301 19.974 14.0937 19.974C12.7572 19.974 12.4133 19.1839 10.8705 19.1839C9.36697 19.1839 8.83239 20 7.60994 20C6.38748 20 5.53452 18.8599 4.5538 17.4597C3.41782 15.8156 2.5 13.2613 2.5 10.8371C2.5 6.94869 4.98421 4.88649 7.42912 4.88649C8.72823 4.88649 9.81114 5.75458 10.6268 5.75458C11.4031 5.75458 12.6137 4.83448 14.0917 4.83448C14.6518 4.83448 16.6643 4.88649 17.989 6.81868ZM13.3901 3.18832C14.0013 2.45024 14.4337 1.42614 14.4337 0.40204C14.4337 0.260026 14.4219 0.116012 14.3963 0C13.4019 0.0380038 12.2187 0.674067 11.5053 1.51615C10.9452 2.16422 10.4224 3.18832 10.4224 4.22642C10.4224 4.38244 10.4479 4.53845 10.4597 4.58846C10.5226 4.60046 10.6248 4.61446 10.727 4.61446C11.6193 4.61446 12.7415 4.0064 13.3901 3.18832Z'
                  fill='white'
                />
              </svg>
            </span>
            <>Login with Apple</>
          </Button>
          {!showEmailLogin && (
            <Button
              fullWidth={true}
              childType={!isMobile ? 'leftAligned' : 'center'}
              style='secondary'
              onClick={() => {
                setShowEmailLogin(true)
              }}
            >
              <div className='flex items-center gap-3'>
                <div className='group text-howdy-primary-green group-hover:text-white '>
                  <EnvelopeSimple width={20} height={20} color='currentColor' />
                </div>{' '}
                Login with an email
              </div>
            </Button>
          )}
        </div>

        <form
          className={`flex w-full flex-col items-center lg:items-start ${
            showEmailLogin ? 'block' : 'hidden'
          }`}
          onSubmit={loginForm.handleSubmit(onSubmitLogin)}
        >
          <Input
            label='Email'
            type='email'
            placeholder='Enter email'
            name='email'
            input={{
              ...loginForm.register('email', {
                required: 'Required',
              }),
            }}
          />
          <PasswordInput
            label='Password'
            placeholder='Password'
            name='password'
            error={loginForm.errors && loginForm.errors.password?.message}
            register={loginForm.register('password', {
              required: 'Required',
            })}
          />
          <div className='mb-4 mt-2 flex w-full justify-center lg:justify-end'>
            <LinkButton
              href='/password-recover'
              text={'Forgot password'}
              color={'text-[#646464]'}
            />
          </div>
          <div>
            <Button type='submit'>Login</Button>
          </div>
        </form>
        <div className='mt-4 flex justify-center xl:justify-start'>
          <LinkButton href={`/sign-up${getQuery()}`} text={'SIGN UP'} preText='Need an account? ' />
        </div>
      </div>
      <TCModal
        display={showTCModal}
        isUpdate={shouldUpdateTcs}
        closeAction={closeTCModal}
        acceptAction={shouldUpdateTcs ? updateTCs : onAcceptTCProvider}
      />
      <NoUserModal
        display={showNoUserModal}
        closeAction={() => {
          setShowNoUserModal(false)
        }}
      />
      <ProviderTCModal
        display={showTCProvider}
        acceptAction={onAcceptTCProvider}
        closeAction={() => setShowTCProvider(false)}
        openTCAction={() => {
          setShowTCProvider(false)
          setShouldUpdateTcs(false)
          setShowTCModal(true)
        }}
        provider='google'
      />
    </WelcomeLayout>
  )
}
