import Base from '@howdy/core/elements/Modal/Base'
import Button from '@howdy/core/elements/Button'
import Link from 'next/link'
import Circle from '@howdy/core/elements/Circle'
import UserCircleError from '@howdy/core/elements/Icons/UserCircleError'

export default function NoUserModal({ display, closeAction }) {
  return (
    <Base isOpen={display} onRequestClose={closeAction}>
      <div className='relative'>
        <div className='flex w-full justify-center p-4'>
          <Circle borderColor='#DCDCDC' bgColor='white' className='text-howdy-primary-green'>
            <UserCircleError />
          </Circle>
        </div>
        <div className='w-full overflow-y-auto px-4 lg:w-[460px]'>
          <h1 className='mb-4 text-center font-worksans text-xl font-bold'>User not found</h1>
          <p className='text-center font-worksans text-sm text-[#464646]'>
            Looks like this email doesn’t have a Howdy account. Please go to the sign up page to
            create one.
          </p>
        </div>
      </div>
      <div className='flex w-full justify-center gap-x-5 pt-4'>
        <Link href={'/sign-up'} className='w-full md:w-auto'>
          <Button fullWidth>Sign up</Button>
        </Link>
      </div>
    </Base>
  )
}
